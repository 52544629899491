import React, { lazy, Suspense, useEffect, useContext, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import routers from './router';
import './App.scss';
import { Footer, Header } from './components';
import { SlotMentionContext } from './constant';

function App() {
  const SlotMentionRef= useRef({});
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);
  return (
    <SlotMentionContext.Provider value={SlotMentionRef}>
      <div className="App">
        <Header></Header>
        <Routes>
          {routers.map((item, index) => {
            const SingleDynamicComponent = lazy(() => item.component());
            return (
              <Route
                key={index}
                path={item.path}
                element={
                  <Suspense>
                    <SingleDynamicComponent />
                  </Suspense>
                }
              ></Route>
            );
          })}
        </Routes>
        <Footer></Footer>
      </div>
    </SlotMentionContext.Provider>
  );
}

export default App;
