import { Link } from 'react-router-dom';
import './index.scss';

const Footer = () => {
  return (
    <div className="footer-wrap">
      <footer className="footer">
        <div className="main-content">
          <div className="footer_call">
            <i className="iconfont icon-24gf-telephone2"></i>
            <div className="footer_call-info">
              <div className="footer_call-info-txt">咨询热线</div>
              <div className="footer_call-info-phone">18516369805</div>
            </div>
          </div>
          <div className="footer_copy">版权所有：上海如虎网络科技有限公司</div>
          <div className="footer_address">总部地址：上海市杨浦区内江路191号111幢203室</div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="main-content">
          <span className="footer-left_copy">Copyright © 2022-2023 </span>
          <a className="footer-left_mail" href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
            ICP备案号：沪ICP备2023008742号-1
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
