import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { SlotMentionContext } from '../../constant';
import './index.scss';

const Menus = [
  {
    title: '首页',
    link: '/index?slide=1',
    key: 'index',
  },
  {
    title: '业务',
    link: '/index?slide=2',
    key: 'business',
  },
  {
    title: '周边',
    link: '/index?slide=3',
    key: 'product',
  },
  {
    title: '开发',
    link: '/index?slide=4',
    key: 'develop',
  },
  {
    title: '方案',
    link: '/index?slide=5',
    key: 'plan',
  },
  {
    title: '关于',
    link: '/index?slide=6',
    key: 'about',
  },
  {
    title: '联系',
    link: '/index?slide=7',
    key: 'contact',
  },
  {
    title: '登录/注册',
    link: '/login',
    key: 'login',
  },
];
const HomeMenus = Menus.slice(0, Menus.length - 1)
const Header = () => {
  const SlotMentionRef = useContext(SlotMentionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState('');

  const handleMove = (key) => {
    console.log('key', key);
    let tempKey = key;
    if (!key) return;
    if (key === 'register') {
      tempKey = 'login';
    }
    if (tempKey === 'detail' || tempKey === 'list') {
      tempKey = 'product'
    }
    const parentReact = document.querySelector(`.header-action`).getBoundingClientRect();
    const rect = document.querySelector(`.header-action_item-${tempKey}`).getBoundingClientRect();
    const offsetLeft = rect.x - parentReact.x + (rect.width - 40) / 2;
    document.querySelector<HTMLDivElement>(`.header-action_move`).style.left = `${offsetLeft}px`;
  };

  const handleMenuClick = useCallback((item, i?) => {
    // 首页内部跳转时，不执行跳转方法
    if (!(['/', '/index'].includes(location.pathname) && item.link.includes('slide'))) {
      item.link && navigate(item.link);
    }
    handleMove(item.key);
    setActiveKey(item.key);
    !isNaN(i) && SlotMentionRef?.current?.fullpageApi?.moveTo(i + 1);
  }, [location.pathname, navigate]);

  const handleMouse = (item?) => {
    if (item) {
      handleMove(item.key);
    } else {
      handleMenuInit();
    }
  };

  const handleMenuInit = useCallback(() => {
    console.log('handleMenuInit', activeKey)
    handleMove(activeKey);
  }, [activeKey]);

  const navBarChange = useCallback((origin, destination, direction) => {
    const menu = Menus.find((_, i) => i === destination.index);
    console.log('navBarChange --- menu', menu);
    handleMenuClick(menu);
  }, [handleMenuClick]);

  useEffect(() => {
    handleMenuInit();
  }, [activeKey, handleMenuInit]);

  useEffect(() => {
    const index = new URLSearchParams(location.search).get('slide');
    const menu = index ? Menus[+index - 1]: {};
    console.log('HomeMenus', HomeMenus)
    console.log('location - menu - info', menu, index, location);
    setActiveKey((menu as any).key ?? location.pathname.slice(1));
    setTimeout(() => {
      SlotMentionRef?.current?.fullpageApi?.moveTo(index);
    }, 500);
  }, [location, SlotMentionRef]);

  useEffect(() => {
    SlotMentionRef.current.navBarApi = {
      navBarChange,
    };
  }, [SlotMentionRef, navBarChange]);

  return (
    <div className="header">
      <div className="main-content">
        <div className="header-left">
          <Link to="/index?slide=1" className="header-logo"></Link>
          <div className="header-slogan">
            Unity3d技术美术<br></br>
            专业美术外包服务
          </div>
        </div>
        <div className="header-right">
          <div className="header-action" onMouseLeave={() => handleMouse()}>
            {Menus.map((item, i) => (
              <div
                key={item.title}
                className={`
                header-action_item 
                header-action_item-${item.key} 
                ${item.key === activeKey ? 'active' : ''}
                ${activeKey !== 'index' ? 'small' : ''}
                `}
                onClick={() => handleMenuClick(item, i)}
                onMouseOver={() => handleMouse(item)}
              >
                {item.title}
              </div>
            ))}
            <div className="header-action_move"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
